<template>
    <b-card title="Zdravniki">
        <div class="d-flex justify-content-end ">
            <b-button variant="primary" @click="openAddDoctorModal">
                <span class="flex items-center gap-2">
                    <fa icon="plus" size="xs"/>
                    Dodaj zdravnika
                </span>
            </b-button>
        </div>
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">
            <span v-if="props.column.display_type === 1" class="d-flex justify-content-center">
                <b-button variant="warning" @click="openEditDoctorModal(props.row)"><fa icon="edit"/></b-button>
                <b-button class="ml-1" variant="danger" @click="deleteRow(props.row)"><fa icon="trash"/></b-button>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>

        <doctor-modal ref="doctorModal" @itemAdded="loadData" @itemEdited="loadData"/>
    </b-card>
</template>

<script>
    import { BCard, BButton } from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'
    import DoctorModal from '@/views/admin/doctors/components/DoctorModal.vue'

    export default {
        components: {
            DoctorModal,
            Table,
            BCard,
            BButton
        },
        data() {
            return {
                columns: [
                    {
                        field: 'first_name',
                        label: 'Ime',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'last_name',
                        label: 'Priimek',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'title_1',
                        label: 'Ustanova',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'title_2',
                        label: 'Dodatek k zdravniku',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'actions',
                        label: 'Dejanja',
                        tdClass: 'align-middle',
                        display_type: 1
                    }
                ],
                items: []
            }
        },
        methods: {
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/doctor/')
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            openAddDoctorModal() {
                this.$refs.doctorModal.show()
            },
            openEditDoctorModal(data) {
                this.$refs.doctorModal.show(data)
            },
            async deleteRow(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(`Ali res želite izbrisati zdravnika: ${data.firstname} ${data.lastname}`)
                    if (!confirmDelete) return
                    this.$store.commit('app/START_LOADING')
                    await this.$http.delete(`/api/private/v1/doctor/${data.id}`)
                    this.$printSuccess('Brisanje je bilo uspešno')
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri brisanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>

</style>
