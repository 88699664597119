var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"title":_vm.title,"centered":"","no-close-on-backdrop":""},on:{"cancel":_vm.makeEmptyObject,"close":_vm.makeEmptyObject,"hide":_vm.makeEmptyObject},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Ime"}},[_c('validation-provider',{attrs:{"name":"Ime","rules":"required|min:1|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.doctorObject.first_name),callback:function ($$v) {_vm.$set(_vm.doctorObject, "first_name", $$v)},expression:"doctorObject.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Priimek"}},[_c('validation-provider',{attrs:{"name":"Priimek","rules":"required|min:1|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.doctorObject.last_name),callback:function ($$v) {_vm.$set(_vm.doctorObject, "last_name", $$v)},expression:"doctorObject.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ustanova"}},[_c('validation-provider',{attrs:{"name":"Ustanova","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{attrs:{"options":_vm.institutions,"label":"institution","reduce":function (x) { return x.id; },"clearable":false,"filterable":true,"searchable":true},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var institution = ref.institution;
var institution_addition = ref.institution_addition;
return [_c('span',[_vm._v(_vm._s((institution + " " + institution_addition)))])]}},{key:"selected-option",fn:function(ref){
var institution = ref.institution;
var institution_addition = ref.institution_addition;
return [_c('span',[_vm._v(_vm._s((institution + " " + institution_addition)))])]}}],null,true),model:{value:(_vm.doctorObject.institution_id),callback:function ($$v) {_vm.$set(_vm.doctorObject, "institution_id", $$v)},expression:"doctorObject.institution_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Zapri ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.editing ? _vm.edit() :_vm.add()}}},[_vm._v(" "+_vm._s(_vm.editing ? 'Uredi' : 'Dodaj')+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }